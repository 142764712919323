var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loadingArticlesTable)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.shopArticles,"single-select":_vm.singleSelect,"sort-by":"pathname","show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-switch',{staticClass:"pa-3",attrs:{"label":"Single select"},model:{value:(_vm.singleSelect),callback:function ($$v) {_vm.singleSelect=$$v},expression:"singleSelect"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"600px","fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","eager":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"id":"dialog-ebay-v-toolbar","dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Settings")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.$refs.ebayCategories.saveEbayCategory(); _vm.$refs.ebayPolicies.savePaymentShippingReturnPolicy(); _vm.dialog = false}}},[_vm._v(" Save ")])],1)],1),[_c('ebay-categories-component',{ref:"ebayCategories",attrs:{"policy-position":"category","sw-categories":_vm.swCategories}})],[_c('ebay-policies-component',{ref:"ebayPolicies",attrs:{"policy-position":"category"}})]],2)],1)],1)]},proxy:true},{key:"item.categoryPath",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'https://' + _vm.$store.state.swApiQuery.apiurl + '/listing/index/sCategory/' + item.id,"target":"_blank"}},[_vm._v(" "+_vm._s(item.pathname)+" ")])]}},{key:"item.discountPercent",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":(item.vtoolPseudoPrice === '1' ) ? 'green' : 'red'},on:{"click":function($event){_vm.pricesActionMessage = ''}}},'v-icon',attrs,false),on),[_vm._v(" mdi-brightness-percent ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"grey","icon":"mdi-brightness-percent"}},[_vm._v(" "+_vm._s(item.streamId ? 'mdi-air-filter' : 'mdi-shape-outline')+" ")]),_vm._v(" "+_vm._s(item.vtoolPseudoPrice)+" ")]}},{key:"default",fn:function(dialogDiscount){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(" Update Category: "+_vm._s(item.id)+" ")]),_c('v-card-text',[_c('div',{staticClass:"text-h2 pa-12"},[_vm._v(" Percent +-: "),_c('v-text-field',{attrs:{"filled":"","label":"Percent","append-icon":"mdi-percent-outline"},model:{value:(_vm.discountPercent),callback:function ($$v) {_vm.discountPercent=$$v},expression:"discountPercent"}}),_vm._v(" "+_vm._s(_vm.pricesActionMessage)+" ")],1)]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.updateNormalizePrices('normalizePrices', item.id)}}},[_vm._v(" Normalize Prices ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.updateNormalizePrices('updatePrices', item.id)}}},[_vm._v(" Update Prices ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialogDiscount.value = false}}},[_vm._v(" Close ")])],1)],1)]}}],null,true)})]}},{key:"item.ebayPolicies",fn:function(ref){
var item = ref.item;
return [(item.ebaypaymentid && item.ebayshippingid && item.ebayreturnid)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-shape ")])]}}],null,true)},[_c('span',[_vm._v(" Payment Profiles ID: "+_vm._s(item.ebaypaymentid)+" "),_c('br'),_vm._v(" Shipping Policy Profile ID: "+_vm._s(item.ebayshippingid)+" "),_c('br'),_vm._v(" Return Policy Profile ID: "+_vm._s(item.ebayreturnid)+" ")])])],1):(!item.ebaypaymentid && !item.ebayshippingid && !item.ebayreturnid)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"yellow"},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-shape ")])]}}],null,true)},[_c('span',[_vm._v("Globale Einstellungen über UserProfil")])])],1):(!item.ebaypaymentid || !item.ebayshippingid || !item.ebayreturnid)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-shape ")])]}}],null,true)},[_c('span',[_vm._v(" Payment Profiles ID: "+_vm._s(item.ebaypaymentid)+" "),_c('br'),_vm._v(" Shipping Policy Profile ID: "+_vm._s(item.ebayshippingid)+" "),_c('br'),_vm._v(" Return Policy Profile ID: "+_vm._s(item.ebayreturnid)+" ")])])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}],null,false,3707018479),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.preloader),callback:function ($$v) {_vm.preloader=$$v},expression:"preloader"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.preloaderText)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }