<template>
  <div>
    <v-data-table
      v-if="!loadingArticlesTable"
      v-model="selected"
      :headers="headers"
      :items="shopArticles"
      :single-select="singleSelect"
      sort-by="pathname"
      show-select
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="white"
        >
          <v-switch
            v-model="singleSelect"
            label="Single select"
            class="pa-3"
          />
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <v-dialog
            v-model="dialog"
            max-width="600px"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            eager
          >
            <v-card>
              <v-toolbar
                id="dialog-ebay-v-toolbar"
                dark
                color="primary"
              >
                <v-btn
                  icon
                  dark
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Settings</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-btn
                    dark
                    text
                    @click="$refs.ebayCategories.saveEbayCategory(); $refs.ebayPolicies.savePaymentShippingReturnPolicy(); dialog = false"
                  >
                    Save
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

              <template>
                <ebay-categories-component
                  ref="ebayCategories"
                  policy-position="category"
                  :sw-categories="swCategories"
                />
              </template>

              <template>
                <ebay-policies-component
                  ref="ebayPolicies"
                  policy-position="category"
                />
              </template>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.categoryPath="{ item }">
        <a
          :href="'https://' + $store.state.swApiQuery.apiurl + '/listing/index/sCategory/' + item.id"
          target="_blank"
        >
          {{ item.pathname }}
        </a>
      </template>
      <template v-slot:item.discountPercent="{ item }">
        <v-dialog
          transition="dialog-top-transition"
          max-width="600"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              :color="(item.vtoolPseudoPrice === '1' ) ? 'green' : 'red'"
              v-bind="attrs"
              v-on="on"
              @click="pricesActionMessage = ''"
            >
              mdi-brightness-percent
            </v-icon>
            <v-icon
              small
              class="mr-2"
              color="grey"
              icon="mdi-brightness-percent"
            >
              {{ item.streamId ? 'mdi-air-filter' : 'mdi-shape-outline' }}
            </v-icon>
            {{ item.vtoolPseudoPrice }}
          </template>
          <template v-slot:default="dialogDiscount">
            <v-card>
              <v-toolbar
                color="primary"
                dark
              >
                Update Category: {{ item.id }}
              </v-toolbar>
              <v-card-text>
                <div class="text-h2 pa-12">
                  Percent +-:
                  <v-text-field
                    v-model="discountPercent"
                    filled
                    label="Percent"
                    append-icon="mdi-percent-outline"
                  />
                  {{ pricesActionMessage }}
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  text
                  @click="updateNormalizePrices('normalizePrices', item.id)"
                >
                  Normalize Prices
                </v-btn>
                <v-btn
                  text
                  @click="updateNormalizePrices('updatePrices', item.id)"
                >
                  Update Prices
                </v-btn>
                <v-btn
                  text
                  @click="dialogDiscount.value = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </template>
      <template v-slot:item.ebayPolicies="{ item }">
        <div v-if="item.ebaypaymentid && item.ebayshippingid && item.ebayreturnid">
          <v-tooltip
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                color="green"
                v-bind="attrs"
                v-on="on"
                @click="editItem(item)"
              >
                mdi-shape
              </v-icon>
            </template>
            <span>
              Payment Profiles ID: {{ item.ebaypaymentid }} <br>
              Shipping Policy Profile ID: {{ item.ebayshippingid }} <br>
              Return Policy Profile ID: {{ item.ebayreturnid }}
            </span>
          </v-tooltip>
        </div>
        <div v-else-if="!item.ebaypaymentid && !item.ebayshippingid && !item.ebayreturnid">
          <v-tooltip
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                color="yellow"
                v-bind="attrs"
                v-on="on"
                @click="editItem(item)"
              >
                mdi-shape
              </v-icon>
            </template>
            <span>Globale Einstellungen über UserProfil</span>
          </v-tooltip>
        </div>
        <div v-else-if="!item.ebaypaymentid || !item.ebayshippingid || !item.ebayreturnid">
          <v-tooltip
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                color="red"
                v-bind="attrs"
                v-on="on"
                @click="editItem(item)"
              >
                mdi-shape
              </v-icon>
            </template>
            <span>
              Payment Profiles ID: {{ item.ebaypaymentid }} <br>
              Shipping Policy Profile ID: {{ item.ebayshippingid }} <br>
              Return Policy Profile ID: {{ item.ebayreturnid }}
            </span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      v-model="preloader"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          {{ preloaderText }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { globalVars } from '@/globalVars.js'
  import EbayPoliciesComponent from '../components/core/EbayPoliciesComponent'
  import EbayCategoriesComponent from '../components/core/EbayCategoriesComponent'
  export default {
    components: {
      EbayPoliciesComponent,
      EbayCategoriesComponent,
    },
    // components: {
    //   EbayPoliciesComponent: () => import('../components/core/EbayPoliciesComponent'),
    // },

    data: () => ({
      swApiUrl: globalVars.swApiUrl,
      ebayApiUrl: globalVars.ebayApiUrl,
      ebayBusinesspolicyUrl: globalVars.ebayBusinesspolicyUrl,
      // apiurl: '',
      // apiuser: '',
      // apikey: '',
      sandbox: globalVars.sandbox,
      // apiQuery: {},
      dialog: false,
      dialog1: true,
      preloader: true,
      preloaderText: 'Loading...',
      loadingArticlesTable: true,
      singleSelect: false,
      ebayItemLink: globalVars.ebayItemLink,
      selected: [],
      headers: [
        { text: 'Kategorie Pfad > Name', align: 'start', sortable: true, value: 'categoryPath' },
        { text: 'eBay category ID', value: 'ebaycategoryid' },
        // { text: 'Fat (g)', value: (name) => this.compareShopToEbayArticles(name) },
        // { text: 'Fat (g)', value: `${this.test}` },
        { text: 'Stock (main)', value: 'articleCount' },
        { text: 'Discount %', value: 'discountPercent' },
        { text: 'eBay Policies', value: 'ebayPolicies' },
        { text: 'eBay Category', value: 'actions', sortable: false },
      ],
      shopArticles: {},
      // ebayArticles: {},
      editedIndex: -1,
      editedItem: {},
      descriptionLimit: 160,
      entries: [],
      isLoading: false,
      model: null,
      // search: null,
      discountPercent: 0,
      pricesActionMessage: '',
      swCategories: '',
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Send Item to eBay'
      },
      fields () {
        if (!this.model) return []

        return Object.keys(this.model).map(key => {
          // console.log('Auswahl!', this.model[key])
          this.editedItem.ebaycategoryid = this.model.CategoryID
          return {
            key,
            value: this.model[key] || 'n/a',
          }
        })
      },
      items () {
        return this.entries.map(entry => {
          const CategoryName = entry.CategoryName.length > this.descriptionLimit
            ? entry.CategoryName.slice(0, this.descriptionLimit) + '...'
            : entry.CategoryName

          return Object.assign({}, entry, { CategoryName })
        })
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      // search (val) {
      //   // Items have already been loaded
      //   if (this.items.length > 0) return

      //   // Items have already been requested
      //   if (this.isLoading) return

      //   this.isLoading = true

      //   // Lazily load input items
      //   // https://stackoverflow.com/questions/28681875/php-multi-dimensional-array-w-parent-id-to-category-taxonomy-array-ebay-style
      //   fetch(this.ebayApiUrl + '/getCategories.php')
      //     .then(res => res.json())
      //     .then(res => {
      //       this.swCategories = res
      //       const { count, entries } = res
      //       this.count = count
      //       this.entries = entries
      //       console.log('FromEbay:', res)
      //     })
      //     .catch(err => {
      //       console.log(err)
      //     })
      //     .finally(() => (this.isLoading = false))
      // },

    },

    created () {
      // this.getApiData()
      // this.initialize()
    },

    mounted: function () {
      this.fetchShopArticles()
      // this.fetchEbayArticles()
    },

    methods: {

      updateNormalizePrices (action, categoryId) {
        console.log('updateNormalizePrices: ', action, categoryId)
        console.log('the swApiQuery for BAuth:', this.$store.state.swApiQuery)
        this.$http.get(this.swApiUrl + '/sw-api/simpleRequest.php', {
          params: {
            action: action,
            apiEndPoint: 'variants',
            discountPercent: this.discountPercent,
            categoryId: categoryId,
          },
        }).then((resp) => {
          this.pricesActionMessage = resp.data.data.data
          this.fetchShopArticles()
          console.log('simpleRequest:', resp.data)
        })

        // this.$http.get('https://' + this.$store.state.swApiQuery.apiurl + '/api/variants/' + action + '?percent=-10&categoryId=' + categoryId, {
        // this.$http.get(this.swApiUrl + '/sw-api/simpleRequest.php', {
        //   auth: {
        //     username: this.$store.state.swApiQuery.apiuser,
        //     password: this.$store.state.swApiQuery.apikey,
        //   },
        // }).then((resp) => {
        //   console.log('updateNormalizePrices DONE: ', resp.data)
        // })
      },

      fetchShopArticles: function () {
        // https://dev.to/ljnce/use-axios-api-with-vue-cli-54i2
        // https://restcountries.eu/rest/v1/all
        this.preloader = true
        this.preloaderText = 'Kategorien werden geladen...'
        this.$http.get(this.swApiUrl + '/sw-api/getCategories.php').then((resp) => {
          console.log('getCategories DATA:', resp.data.data)
          this.shopArticles = resp.data.data
          this.loadingArticlesTable = false
          this.preloader = false
        })
      },

      editItem (item) {
        this.editedIndex = this.shopArticles.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
        console.log('itemEdit:', item)
        this.$refs.ebayCategories.setEbayCatValuesNameIdEbayId(item.name, item.id, item.ebaycategoryid)
        this.$refs.ebayCategories.getVtoolSettings(item.id)
        this.$refs.ebayPolicies.setOverlayTrue()
        this.$refs.ebayPolicies.getEbayUserPaymentShippingReturnPolicy(item.id)
      },

      close () {
        this.fetchShopArticles()
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        this.preloader = true
        this.preloaderText = 'eBay Kategorie wird gespeichert angelegt...'
        if (this.editedIndex > -1) {
          Object.assign(this.shopArticles[this.editedIndex], this.editedItem)
        } else {
          this.shopArticles.push(this.editedItem)
        }
        this.close()

        const swcategoryid = this.editedItem.id
        const swcategoryname = this.editedItem.name
        const ebaycategoryid = this.editedItem.ebaycategoryid
        console.log('CATEGORY...: ', this.editedItem, swcategoryid, swcategoryname, ebaycategoryid)

        this.$http.get(this.swApiUrl + '/sw-api/saveCategoryEbaycategoryid.php?swcategoryid=' + swcategoryid + '&swcategoryname=' + swcategoryname + '&ebaycategoryid=' + ebaycategoryid).then((resp) => {
          this.loadingArticlesTable = false
          this.preloader = false
          console.log('Article added! ', resp.data)
        })
      },

    },
  }
</script>

<style>
  #dialog-ebay-v-toolbar{
    width: 100%;
    position: fixed;
    z-index: 9999;
  }
  .inputEbayID input[type='number'] {
      -moz-appearance:textfield;
  }
  .inputEbayID input::-webkit-outer-spin-button,
  .inputEbayID input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
  .tipsy{
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 0.85em;
      line-height: 1.42857143;
      text-align: left;
      white-space: pre-wrap;
  }
  .tipsy-inner{
      text-align: left;
  }
</style>
